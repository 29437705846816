import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField,
    TextInput,
    TopToolbar,
    DateField
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";


const PostListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);

const postFilters = [
    <TextInput name="subjectId" label="Индификатор Subject" source="subjectId" defaultValue=""/>,
    <DateField name="date" label="Дата" source="date" defaultValue=""/>,
    <TextInput name="symptomId" label="Индификатор Symptom Id" source="symptomId" defaultValue=""/>,
    <TextInput name="symptomValue" label="Индификатор Symptom Value" source="symptomValue" defaultValue=""/>,
];

const services = {'services': 'account_service'}

export const symptomDataList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <TextField source="subjectId" label="Индификатор Subject"/>
            <DateField source="date" label="Дата"/>
            <TextField source="symptomId" label="Индификатор Symptom Id"/>
            <TextField source="symptomValue" label="Индификатор Symptom Value"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-symptom-data-for-delete"
                type="delete"
                redirect_path="/admin-symptoms-data-list"
            />
        </Datagrid>
    </List>
);

