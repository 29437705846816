import {required, NumberInput} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const FieldGroup = ({label, children, style}: {
    label: string;
    children: React.ReactNode,
    style?: React.CSSProperties
}) => (
    <div style={style}>
        <h3>{label}</h3>
        {children}
    </div>
);

const EventRefForm: React.FC = () => {
    return (
        <div>

            <NumberInput
                source="period"
                name="period"
                label="Период"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <CustomReferenceInput
                source="calendarId"
                reference="admin-calendar"
                label="Индификатор calendar"
                name="calendarId"
                filter={{services: 'account_service', resource: "admin-calendars-ref-list"}}
                meta={{resource: 'admin-calendars', model_name: 'CalendarRef'}}
            />
            <CustomReferenceInput
                source="periodTypeId"
                reference="admin-period"
                label="Индификатор period"
                name="periodTypeId"
                filter={{services: 'account_service', resource: "admin-periods-type-ref-list"}}
                meta={{resource: 'admin-periods-type', model_name: 'PeriodTypeRef'}}
            />
            <CustomReferenceInput
                source="eventTimeTypeId"
                reference="admin-period"
                label="Индификатор period"
                name="eventTimeTypeId"
                filter={{services: 'account_service', resource: "admin-events-time-type-ref-list"}}
                meta={{resource: 'admin-events-time', model_name: 'EventTimeRef'}}
            />

        </div>
    );
};

export default EventRefForm;
