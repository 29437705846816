import * as React from 'react';
import { AppBar, TitlePortal } from 'react-admin';

import { AppBarToolbar } from './AppBarToolbar';

const CustomAppBar = () => {
    return (
        <AppBar color="secondary" toolbar={<AppBarToolbar />}>
            <TitlePortal />
        </AppBar>
    );
};

export default CustomAppBar;
