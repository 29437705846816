import {DateInput, ImageField, ImageInput, required, TextInput} from 'react-admin';
import * as React from 'react';
import TempPasswordInput from "../elements/ButtonGeneratedTempPassword";
import CustomReferenceInput from "../elements/CustomReferenceInput";


const FieldGroup = ({label, children, style}: {
    label: string;
    children: React.ReactNode,
    style?: React.CSSProperties
}) => (
    <div style={style}>
        <h3>{label}</h3>
        {children}
    </div>
);

const AdviceRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="code"
                name="code"
                label="Код"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="text"
                name="text"
                label="Текст"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <ImageInput
                accept="image/*"
                multiple={false}
                source="image"
                name="image"
                label="Изображение"
                placeholder={<p>Перетащите изображение</p>}>
                <ImageField source="src" title="title"/>
            </ImageInput>
            <CustomReferenceInput
                source="languageId"
                reference="admin-languages"
                label="Язык"
                name="languageId"
                filter={{services: 'account_service', resource: "admin-languages-list"}}
                meta={{resource: 'admin-languages', model_name: 'LanguagesRef'}}
            />

        </div>
    );
};

export default AdviceRefForm;
