import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";
import ArticleRuleRefForm from "../forms/ArticleRuleRefForm";

export const ArticleRuleRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-article-rule-ref', type: 'edit'});

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <ArticleRuleRefForm/>
            </SimpleForm>
        </div>

    )

};

export default ArticleRuleRefFormEdit