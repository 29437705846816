import {required, TextInput, DateInput} from 'react-admin';
import * as React from 'react';


const FieldGroup = ({label, children, style}: {
    label: string;
    children: React.ReactNode,
    style?: React.CSSProperties
}) => (
    <div style={style}>
        <h3>{label}</h3>
        {children}
    </div>
);

const SymptomDataForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="subjectId"
                name="subjectId"
                label="Индификатор Subject"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <DateInput
                source="date"
                name="date"
                label="Дата"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="symptomId"
                name="symptomId"
                label="Индификатор Symptom Id"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="symptomValue"
                name="symptomValue"
                label="Индификатор Symptom Value"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

        </div>
    );
};

export default SymptomDataForm;
