import {AuthProvider} from 'react-admin';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

function setTokenToCookie(response: any) {
    const accessToken = response.data.access_token;
    // const refreshToken = response.data.refresh_token;

    // Устанавливаем токены в cookie
    document.cookie = `access_token=${accessToken}; path=/;`;
    // document.cookie = `refresh_token=${refreshToken}; path=/;`;
}


const getCookie = (name: string) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2];
    }
    return null;
};

function deleteCookie(name: string, path?: string, domain?: string) {
    if (getCookie(name)) {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "/") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

const authProvider: AuthProvider = {
    login: async ({username, password}) => {
        const data = {'phone_number': username, 'password': password};
        return await axios.post(apiUrl + '/access_token', data)
            .then(response => {
                if (
                    response.data.access_token &&
                    response.data.status === 200 &&
                    response.data.details === 'authenticated') {
                    setTokenToCookie(response)
                    return Promise.resolve();

                } else if (
                    response.data.access_token &&
                    response.data.status === 302 &&
                    response.data.details === 'change password') {

                    setTokenToCookie(response)
                    window.location.href = `#/change-password`;

                    return Promise.reject('#/change-password');
                }

            })
            .catch(error => {
                // Обработка ошибки
                if (error.response.status === 400) {
                    return Promise.reject('Неверный логин или пароль');
                } else {
                    return Promise.reject('Произошла ошибка');
                }
            });
    },

    logout: () => {
        deleteCookie('access_token');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        return getCookie('access_token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => Promise.resolve([]),

    changePassword: async (params: {
        data: { newPassword: string; confirmPassword: string };

    }) => {
        const headers = {
            Authorization: `Bearer ${getCookie('access_token')}`, // Получаем токен из localStorage
            'Content-Type': 'application/json'
        };
        // Реализуйте этот метод, чтобы обновлять элемент
        const url = `${apiUrl}/auth/change_password`;
        return await axios.post(url, params.data, {headers})
            .then(response => {

                if (response.data) {
                    window.location.href = `/login#/login`;
                    return Promise.resolve();
                }
            })
            .catch(error => {
                // Обработка ошибки
                if (error.response.status === 400) {
                    return Promise.reject(`${error.response.data.detail}`);
                } else {
                    return Promise.reject('Произошла ошибка');
                }
            });

    }
};

// const getAuthTokensFromLocalStorage = () => {
//     const accessToken = localStorage.getItem('token');
//     const refreshToken = localStorage.getItem('refreshToken');
//     return {accessToken, refreshToken};
// };
//
// const refreshAuthTokens = (refreshToken) => {
//     // Здесь должен быть код для отправки запроса к серверу для обновления токенов
//     // И сохранения новых токенов в localStorage
//     // Например, используя axios или fetch для отправки запроса к вашему серверу
//     // и обработки ответа, содержащего новые токены
//
//     // Пример:
//     return axios.post('/refresh-token', { refreshToken })
//       .then(response => {
//         const newAccessToken = response.data.accessToken;
//         const newRefreshToken = response.data.refreshToken;
//         localStorage.setItem('accessToken', newAccessToken);
//         localStorage.setItem('refreshToken', newRefreshToken);
//         return { newAccessToken, newRefreshToken };
//       });
//
//     // Это всего лишь пример, и реализация зависит от вашего сервера и API
// };

// export const refreshAuth = () => {
//     const {accessToken, refreshToken} = getAuthTokensFromLocalStorage();
//     if (accessToken.exp < Date.now() / 1000) {
//         // This function will fetch the new tokens from the authentication service and update them in localStorage
//         return refreshAuthTokens(refreshToken);
//     }
//     return Promise.resolve();
// }


export default authProvider;