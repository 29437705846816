import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";
import AdviceRefForm from "../forms/AdviceRefForm";

export const AdviceRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-advice-ref', type: 'edit'});

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <AdviceRefForm/>
            </SimpleForm>
        </div>

    )

};

export default AdviceRefFormEdit