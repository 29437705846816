import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';
import AdviceRuleRefForm from '../forms/AdviceRuleRefForm';


export const AdviceRuleRefFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-advice-rule-ref',
        type: 'create',
        redirect_path: 'admin-advices-rule-ref-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <AdviceRuleRefForm/>
            </SimpleForm>
        </div>
    );
};


export default AdviceRuleRefFormCreate