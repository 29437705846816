import * as React from 'react';
import {SimpleForm} from 'react-admin';
import CityRefForm from '../forms/CityRefForm';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";

export const CityRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-city', type: 'edit'});

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <CityRefForm/>
            </SimpleForm>
        </div>

    )

};

export default CityRefFormEdit