import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';
import SymptomDateTypeForm from "../forms/SymptomDataTypeForm";


export const SymptomDateTypeFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-symptom-data-type',
        type: 'create',
        redirect_path: 'admin-symptoms-data-type-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <SymptomDateTypeForm/>
            </SimpleForm>
        </div>
    );
};


export default SymptomDateTypeFormCreate