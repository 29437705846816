import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";
import EventRefForm from '../forms/EventRefForm';

export const EventRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-event-ref', type: 'edit'});

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <EventRefForm/>
            </SimpleForm>
        </div>

    )

};

export default EventRefFormEdit