import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';
import ArticleRuleRefForm from '../forms/ArticleRuleRefForm';


export const ArticleRuleRefFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-article-rule-ref',
        type: 'create',
        redirect_path: 'admin-articles-rule-ref-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <ArticleRuleRefForm/>
            </SimpleForm>
        </div>
    );
};


export default ArticleRuleRefFormCreate