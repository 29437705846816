import {BooleanInput, DateInput, ReferenceInput, required, SelectInput, TextInput} from 'react-admin';
import * as React from 'react';
import TempPasswordInput from "../elements/ButtonGeneratedTempPassword";


const FieldGroup = ({label, children, style}: {
    label: string;
    children: React.ReactNode,
    style?: React.CSSProperties
}) => (
    <div style={style}>
        <h3>{label}</h3>
        {children}
    </div>
);

const RoleRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="name"
                name="name"
                label="Название"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="code"
                name="code"
                label="Код"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

        </div>
    );
};

export default RoleRefForm;
