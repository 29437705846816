import * as React from 'react';
import {SimpleForm} from 'react-admin';
import CityRefForm from "../forms/CityRefForm";
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';
import SymptomRefForm from "../forms/SymptomRefForm";
import SymptomValueForm from "../forms/SymptomValueForm";


export const SymptomValueFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-symptom-value',
        type: 'create',
        redirect_path: 'admin-symptoms-value-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <SymptomValueForm/>
            </SimpleForm>
        </div>
    );
};


export default SymptomValueFormCreate