import * as React from 'react';
import {Edit, SimpleForm} from 'react-admin';
import UserForm from "../forms/UserForm";


export const userEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <UserForm/>
        </SimpleForm>
    </Edit>

);

export default userEdit