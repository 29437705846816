import * as React from 'react';
import {SimpleForm} from 'react-admin';
import LanguageRefForm from "../forms/LanguageRefForm";
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";


export const LanguageRefEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-language', type: 'edit'}
    );

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <LanguageRefForm/>
            </SimpleForm>
        </div>

    )

};

export default LanguageRefEdit
