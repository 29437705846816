import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';
import SymptomDataForm from "../forms/SymptomDataForm";


export const SymptomDataFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-symptom-data',
        type: 'create',
        redirect_path: 'admin-symptoms-data-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <SymptomDataForm/>
            </SimpleForm>
        </div>
    );
};


export default SymptomDataFormCreate