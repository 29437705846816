import * as React from 'react';
import Box from '@mui/material/Box';
import {useState} from 'react';

import {MenuItemLink, MenuProps, useSidebarState, DashboardMenuItem} from 'react-admin';

import Tooltip from '@mui/material/Tooltip';
import EventIcon from '@mui/icons-material/Event';
import SickIcon from '@mui/icons-material/Sick';
import SpaIcon from '@mui/icons-material/Spa';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import SubMenu from "./SubMenu";

type MenuName = 'menuAccountService' | 'menuSymptomService' | 'menuAdviceService' | 'menuEventService';

const Menu = ({dense = false}: MenuProps) => {

    const [open] = useSidebarState();
    const [state, setState] = useState({
        menuAccountService: false,
        menuSymptomService: false,
        menuAdviceService: false,
        menuEventService: false,
    });

    const handleToggle = (menu: MenuName) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem
                primaryText='Главная страница'
            />
            <SubMenu
                handleToggle={() => handleToggle('menuAccountService')}
                isOpen={state.menuAccountService}
                name="Пользователи"
                icon={<ManageAccountsIcon/>}
                dense={dense}
            >
                <Tooltip title="Города" arrow>
                    <MenuItemLink
                        to="/admin-cities-list"
                        state={{_scrollToTop: true}}
                        primaryText='Города'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Языки" arrow>
                    <MenuItemLink
                        to="/admin-languages-list"
                        state={{_scrollToTop: true}}
                        primaryText='Языки'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Гендеры" arrow>
                    <MenuItemLink
                        to="/admin-genders-list"
                        state={{_scrollToTop: true}}
                        primaryText='Гендеры'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Роли" arrow>
                    <MenuItemLink
                        to="/admin-roles-list"
                        state={{_scrollToTop: true}}
                        primaryText='Роли'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Аккаунты" arrow>
                    <MenuItemLink
                        to="/admin-subjects-list"
                        state={{_scrollToTop: true}}
                        primaryText='Аккаунты'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuSymptomService')}
                isOpen={state.menuSymptomService}
                name="Симптомы"
                icon={<SickIcon/>}
                dense={dense}
            >
                <Tooltip title="Типы симптомов" arrow>
                    <MenuItemLink
                        to="/admin-symptoms-data-type-list"
                        state={{_scrollToTop: true}}
                        primaryText='Типы симптомов'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Данные симптомов" arrow>
                    <MenuItemLink
                        to="/admin-symptoms-data-list"
                        state={{_scrollToTop: true}}
                        primaryText='Данные симптомов'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Справочная инфо симтомов" arrow>
                    <MenuItemLink
                        to="/admin-symptoms-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочная инфо симтомов'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Значения симптомов" arrow>
                    <MenuItemLink
                        to="/admin-symptoms-value-list"
                        state={{_scrollToTop: true}}
                        primaryText='Значения симптомов'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuAdviceService')}
                isOpen={state.menuAdviceService}
                name="Полезные статьи и советы"
                icon={<SpaIcon/>}
                dense={dense}
            >
                <Tooltip title="Справочник полезных статей" arrow>
                    <MenuItemLink
                        to="/admin-articles-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочник полезных статей'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Правила, отображения полезных статей" arrow>
                    <MenuItemLink
                        to="/admin-articles-rule-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Правила, отображения полезных статей'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Справочник советов дня" arrow>
                    <MenuItemLink
                        to="/admin-advices-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочник советов дня'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="Правила, отображения полезных советов" arrow>
                    <MenuItemLink
                        to="/admin-advices-rule-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Правила полезные статьи'
                        leftIcon={<HorizontalRuleIcon/>}
                        dense={dense}
                    />
                </Tooltip>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuEventService')}
                isOpen={state.menuEventService}
                name="События"
                icon={<EventIcon/>}
                dense={dense}
            >
                <Tooltip title="Справочник типов событий" arrow>
                    <MenuItemLink
                        to="/admin-events-type-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='Справочник типов событий'
                        leftIcon={<ManageAccountsIcon/>}
                        dense={dense}
                    />
                </Tooltip>
                <Tooltip title="События, которые происходят" arrow>
                    <MenuItemLink
                        to="/admin-events-ref-list"
                        state={{_scrollToTop: true}}
                        primaryText='События, которые происходят'
                        leftIcon={<ManageAccountsIcon/>}
                        dense={dense}
                    />
                </Tooltip>
            </SubMenu>
        </Box>
    );
};

export default Menu;
