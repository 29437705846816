import {DateInput, ImageField, ImageInput, NumberInput, required, TextInput} from 'react-admin';
import * as React from 'react';
import TempPasswordInput from "../elements/ButtonGeneratedTempPassword";
import CustomReferenceInput from "../elements/CustomReferenceInput";


const FieldGroup = ({label, children, style}: {
    label: string;
    children: React.ReactNode,
    style?: React.CSSProperties
}) => (
    <div style={style}>
        <h3>{label}</h3>
        {children}
    </div>
);

const AdviceRuleRefForm: React.FC = () => {
    return (
        <div>
            <NumberInput
                source="age"
                name="age"
                label="Возраст"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <CustomReferenceInput
                source="genderCode"
                reference="admin-genders"
                label="Пол"
                name="genderCode"
                filter={{services: 'account_service', resource: "admin-genders-list"}}
                meta={{resource: 'admin-genders', model_name: 'GenderRef'}}
            />
            <CustomReferenceInput
                source="roleCode"
                reference="admin-roles"
                label="Роль"
                name="roleCode"
                filter={{services: 'account_service', resource: "admin-roles-list"}}
                meta={{resource: 'admin-roles', model_name: 'RoleRef'}}
            />
            <CustomReferenceInput
                source="languageCode"
                reference="admin-languages"
                label="Язык"
                name="languageCode"
                filter={{services: 'account_service', resource: "admin-languages-list"}}
                meta={{resource: 'admin-languages', model_name: 'LanguagesRef'}}
            />
            <CustomReferenceInput
                source="articleId"
                reference="admin-articles"
                label="Индификатор article"
                name="articleId"
                filter={{services: 'account_service', resource: "admin-articles-ref-list"}}
                meta={{resource: 'admin-articles', model_name: 'ArticleRef'}}
            />
        </div>
    );
};

export default AdviceRuleRefForm;
