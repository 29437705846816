import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField, TextInput,
    TopToolbar
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";


const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


// Определение фильтров с использованием полей из модели SubjectResponse
const postFilters = [
    <TextInput name="lastName" label="Фамилия" source="lastName" defaultValue=""/>,
    <TextInput name="firstName" label="Имя" source="firstName" defaultValue=""/>,
    <TextInput name="middleName" label="Отчество" source="middleName" defaultValue=""/>,
    <TextInput name="cityId" label="ID города" source="cityId" defaultValue=""/>,
    <TextInput name="languageId" label="ID языка" source="languageId" defaultValue=""/>,
    <TextInput name="birthday" label="Дата рождения" source="birthday" defaultValue=""/>,
    <TextInput name="genderId" label="ID пола" source="genderId" defaultValue=""/>,
    <TextInput name="dateReg" label="Дата регистрации" source="dateReg" defaultValue=""/>,
    <TextInput name="roleId" label="ID роли" source="roleId" defaultValue=""/>
];

// Определение сервисов
const services = {'services': 'account_service'};

// Экспорт списка субъектов с использованием обновленных полей
export const subjectList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <TextField source="lastName" label="Фамилия"/>
            <TextField source="firstName" label="Имя"/>
            <TextField source="middleName" label="Отчество"/>
            <TextField source="cityId" label="ID города"/>
            <TextField source="languageId" label="ID языка"/>
            <TextField source="birthday" label="Дата рождения"/>
            <TextField source="genderId" label="ID пола"/>
            <TextField source="dateReg" label="Дата регистрации"/>
            <TextField source="roleId" label="ID роли"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-subject-for-delete"
                type="delete"
                redirect_path="/admin-subjects-list"
            />
        </Datagrid>
    </List>
);


