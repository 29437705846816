import * as React from 'react';
import {SimpleForm} from 'react-admin';
import LanguageRefForm from "../forms/LanguageRefForm";
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';


export const LanguageRefFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-language',
        type: 'create',
        redirect_path: 'admin-languages-list'

    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <LanguageRefForm/>
            </SimpleForm>
        </div>
    );
};


export default LanguageRefFormCreate