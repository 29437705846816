import {required, TextInput} from 'react-admin';
import * as React from 'react';


const FieldGroup = ({label, children, style}: {
    label: string;
    children: React.ReactNode,
    style?: React.CSSProperties
}) => (
    <div style={style}>
        <h3>{label}</h3>
        {children}
    </div>
);

const SymptomValueForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="symptomId"
                name="symptomId"
                label="Индификатор Симптома"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="name"
                name="name"
                label="Название"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="code"
                name="code"
                label="Код"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="iconPath"
                name="iconPath"
                label="Путь icon"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

        </div>
    );
};

export default SymptomValueForm;
