import React, {useEffect, useState} from 'react';
import {useDataProvider, ReferenceInput, SelectInput} from 'react-admin';
import {ReferenceInputProps, RaRecord} from 'react-admin';

interface CustomReferenceInputProps extends ReferenceInputProps {
    meta: {
        resource: string;
        model_name: string;
    };

}

const CustomReferenceInput: React.FC<CustomReferenceInputProps> = (props) => {
    const {resource, meta: initialMeta, ...rest} = props;
    const dataProvider = useDataProvider();
    const [meta, setMeta] = useState(initialMeta);

    // Перегрузка для обработки обоих типов событий
    const handleSelectChange = async (
        event: any
    ) => {
        if ("target" in event) {
            // Это событие изменения
            const selectedId = event.target.value;

            // Обновляем meta в зависимости от выбранного города (или другого условия)
            setMeta((prevMeta) => ({
                ...prevMeta,
                resource: meta.resource,  // Задайте новое значение meta
                model: meta.model_name,
            }));

            // После обновления состояния вызовите getMany
            const response = await dataProvider.getMany(meta.resource, {
                ids: [selectedId],
                meta: {...meta, queue: meta.resource, model: meta.model_name},  // Передаем обновленный meta
            });
        } else {
            // Это RaRecord
            console.log("Selected record:", event);
        }
    };

    return (
        <ReferenceInput {...rest} >
            <SelectInput
                source={rest.source}
                name={rest.name}
                label={rest.label}
                optionText={(record: RaRecord) => `${record.name ? record.name : record.code}`}
                sx={{marginRight: '10px', fontSize: '10px'}}
                onChange={handleSelectChange}/>
        </ReferenceInput>
    );
};

export default CustomReferenceInput;