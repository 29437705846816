import * as React from 'react';
import {Create, SimpleForm} from 'react-admin';
import UserForm from "../forms/UserForm";


export const userCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <UserForm/>
        </SimpleForm>
    </Create>

);

export default userCreate