import React from 'react';
import {Button, useRecordContext} from 'react-admin';
import useCustomDeleteController from '../../hooks/UseCustomDeleteControllerHook';

// Путь к вашему кастомному хуку

interface DeleteButtonProps {
    queue: string;
    type: string;
    redirect_path: string;
}

const DeleteCustomButton: React.FC<DeleteButtonProps> = ({queue, type, redirect_path}) => {
    const record = useRecordContext();
    const {isLoading, handleDelete} = useCustomDeleteController({
        queue,
        type,
        redirect_path
    });

    if (!record) return null;

    return (
        <Button
            label="Delete"
            onClick={() => handleDelete(record.id, record)}
            disabled={isLoading}
        />
    );
};

export default DeleteCustomButton;
