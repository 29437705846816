import uploadCapableDataProvider from "../api/providers/data/customDataProvider";

export const handleClickCustom = (data: any, resource: string, queue: string, type: string, record?: any) => {

    let customRequestPromise: any;

    if (type === 'edit') {

        customRequestPromise = uploadCapableDataProvider.update(
            resource,
            {
                id: record.id,
                data: {
                    ...data
                },
                previousData: record,
                meta: {
                    "queue": queue
                }
            }
        );

    } else if (type === 'create') {

        customRequestPromise = uploadCapableDataProvider.create(
            resource,
            {
                data: {
                    ...data
                },
                meta: {
                    "queue": queue
                }
            }
        );

    }

    customRequestPromise.then(() => {
        Promise.resolve('Обновление выполнено успешно');
        // redirect('list', 'dish_categories')
    }).catch((error: any) => {
        console.error('Ошибка при обновлении:', error);
        Promise.reject('Ошибка при обновлении:');
    });
};

export default handleClickCustom