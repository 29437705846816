import {DateInput, ImageField, ImageInput, ReferenceInput, required, SelectInput, TextInput} from 'react-admin';
import * as React from 'react';
import TempPasswordInput from "../elements/ButtonGeneratedTempPassword";
import CustomReferenceInputWithMeta from "../elements/CustomReferenceInput";
import CustomReferenceInput from "../elements/CustomReferenceInput";


const FieldGroup = ({label, children, style}: {
    label: string;
    children: React.ReactNode,
    style?: React.CSSProperties
}) => (
    <div style={style}>
        <h3>{label}</h3>
        {children}
    </div>
);

const SubjectForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="lastName"
                name="lastName"
                label="Фамилия"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="firstName"
                name="firstName"
                label="Имя"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="middleName"
                name="middleName"
                label="Отчество"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <CustomReferenceInput
                source="cityId"
                reference="admin-cities"
                label="Город"
                name="cityId"
                filter={{services: 'account_service', resource: "admin-cities-list"}}
                meta={{resource: 'admin-cities', model_name: 'CityRef'}}
            />
            <CustomReferenceInput
                source="genderId"
                reference="admin-genders"
                label="Пол"
                name="genderId"
                filter={{services: 'account_service', resource: "admin-genders-list"}}
                meta={{resource: 'admin-genders', model_name: 'GenderRef'}}
            />
            <CustomReferenceInput
                source="roleId"
                reference="admin-roles"
                label="Роль"
                name="roleId"
                filter={{services: 'account_service', resource: "admin-roles-list"}}
                meta={{resource: 'admin-roles', model_name: 'RoleRef'}}
            />
            <DateInput
                source="birthday"
                name="birthday"
                label="Дата рождения"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <DateInput
                source="dateReg"
                name="dateReg"
                label="Дата регистрации"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <ImageInput
                accept="image/*"
                multiple={false}
                source="photoPath"
                name="photoPath"
                placeholder={<p>Перетащите изображение</p>}>
                <ImageField source="src" title="title"/>
            </ImageInput>


        </div>
    );
};

export default SubjectForm;
