import {Card, CardContent, CardHeader} from "@mui/material";


export const Dashboard = () => (
    <Card sx={{
        marginTop: '50px',
        textAlign: 'left'
    }}
          variant="outlined">
        <CardHeader title="Добро пожаловать в систему ANA-admin"/>
        <CardContent>
            Удобное приложение для будущих или молодых мам, в котором трекеры и календари помогают понять, что с ней происходит, что будет и когда это случится.
            Также данное приложение помогает понять что нормально, а на что следует обратить внимание.
        </CardContent>
    </Card>
);