import * as React from 'react';
import {SimpleForm} from 'react-admin';
import CityRefForm from "../forms/CityRefForm";
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';


export const CityRefFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-city',
        type: 'create',
        redirect_path: 'admin-cities-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <CityRefForm/>
            </SimpleForm>
        </div>
    );
};


export default CityRefFormCreate