import {useCreate, useNotify, useRedirect} from 'react-admin';

interface UseCustomCreateControllerProps {
    queue: string;
    type: string;
    redirect_path: string;
    resource?: string;
}

const useCustomCreateController = ({
                                       queue,
                                       type,
                                       redirect_path,
                                       resource = 'account_service'
                                   }: UseCustomCreateControllerProps) => {
    const [create, {isLoading, data}] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleClick = async (data: any) => {
        try {
            await create(resource, {data: {...data, queue, type}});
            notify('Record created successfully', {type: 'success'});
            redirect(`/${redirect_path}`);
        } catch ({message}) {
            notify(`Error: ${message}`, {type: 'warning'});
        }
    };

    return {isLoading, handleClick};
};

export default useCustomCreateController;
