import * as React from 'react';
import {Edit, SimpleForm} from 'react-admin';
import GenderRefForm from "../forms/GenderRefForm";
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";


export const GenderRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-gender', type: 'edit'}
    );

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <GenderRefForm/>
            </SimpleForm>
        </div>

    )

};

export default GenderRefFormEdit