import {useEditController} from 'react-admin';
import handleClickCustom from "../systemHelpers/CustomHandleClick";

interface UseCustomEditControllerProps {
    queue: string;
    type: string;
    resource?: string;
}

const useCustomEditController = (
    {
        queue,
        type,
        resource = 'account_service'
    }: UseCustomEditControllerProps) => {
    const {record, isLoading} = useEditController({
        resource,
        queryOptions: {
            meta: {
                queue
            }
        }
    });

    const handleClick = (data: any) => {
        return handleClickCustom(data, resource, queue, type, record);
    };

    return {record, isLoading, handleClick};
};

export default useCustomEditController;
