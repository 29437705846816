import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';
import ArticleRefForm from '../forms/ArticleRefForm';


export const ArticleRefFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-article-ref',
        type: 'create',
        redirect_path: 'admin-articles-ref-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <ArticleRefForm/>
            </SimpleForm>
        </div>
    );
};


export default ArticleRefFormCreate