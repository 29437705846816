import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';
import AdviceRefForm from '../forms/AdviceRefForm';
import EventRefForm from '../forms/EventRefForm';


export const EventRefFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-event-ref',
        type: 'create',
        redirect_path: 'admin-events-ref-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <EventRefForm/>
            </SimpleForm>
        </div>
    );
};


export default EventRefFormCreate