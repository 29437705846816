import * as React from 'react';
import {SimpleForm} from 'react-admin';
import CityRefForm from '../forms/CityRefForm';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";
import SymptomRefForm from '../forms/SymptomRefForm';

export const SymptomRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-symptom-ref', type: 'edit'});

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <SymptomRefForm/>
            </SimpleForm>
        </div>

    )

};

export default SymptomRefFormEdit