import {useDelete, useNotify, useRedirect} from 'react-admin';

interface UseCustomDeleteControllerProps {
    queue: string;
    type: string;
    redirect_path: string,
    resource?: string;
}

const useCustomDeleteController = ({
                                       queue,
                                       type,
                                       redirect_path,
                                       resource = 'account_service'

                                   }: UseCustomDeleteControllerProps) => {
    const [deleteOne, {isLoading}] = useDelete();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleDelete = async (id: any, record: any) => {
        try {
            await deleteOne(resource, {id, previousData: {...record, queue, type}});
            notify('Record deleted successfully', {type: 'success'});
            redirect(redirect_path);
        } catch (error) {
            if (error instanceof Error) {
                notify(`Error: ${error.message}`, {type: 'warning'});
            } else {
                notify('An unknown error occurred', {type: 'warning'});
            }
        }
    };

    return {isLoading, handleDelete};
};

export default useCustomDeleteController;
